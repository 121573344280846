// https://www.w3schools.com/howto/howto_js_scroll_to_top.asp
document.addEventListener("turbolinks:load", () => {
  const el = document.querySelector('[data-scroll-to]')

  if (el) {
    el.addEventListener('click', scrollTo);
  }
})

// When the user clicks on the button, scroll to the top of the document
function scrollTo(e) {
  const el = e.target
  const scroll_to = el.getAttribute("data-scroll-to")

  $('html,body').animate({ scrollTop: $(`${scroll_to}`).offset().top }, 'slow');
} 
