// Include Lightbox 
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';

document.addEventListener("turbolinks:load", () => {    
  const lightbox = new PhotoSwipeLightbox({
    // may select multiple "galleries"
    gallery: '.gallery.clickable .row',
    
    // Elements within gallery (slides)
    children: 'figure',
    
    // setup PhotoSwipe Core dynamic import
    pswpModule: () => import('photoswipe/dist/photoswipe.esm.js'),
    getViewportSizeFn: function(options, pswp) {
      return {
        x: window.innerWidth,
        y: window.innerHeight
      };
    }
  });
  lightbox.init();
})
