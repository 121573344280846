window.addEventListener('scroll', () => {
  const el = document.querySelector('#page-carousel, #page-banner, #identity-banner')
  if (!el || document.querySelector(".page-layout-identity")) return
  const bgs = el.querySelectorAll('picture')
  // const height = parseInt(window.getComputedStyle(el, null).getPropertyValue('height'), 10);
  // const fadeHeight = height*0.75

  // el.style.opacity = 1 - +window.pageYOffset/fadeHeight+''
  // el.style.top =  +window.pageYOffset/3+'px'
  const nonSlideD = 50 // To prevent delay white space on top when fast scroll top

  bgs.forEach(bg => {
    if (window.pageYOffset > nonSlideD) {
      bg.style.top = (window.pageYOffset * 0.5 - nonSlideD / 2)+'px'
    } else {
      if (bg.style.top != 0) {
        bg.style.top = 0;
      }
    }
    // bg.style.objectPosition = "0, 5%"
  })
  // console.log(el)
})
// console.log('index')
