// https://www.w3schools.com/howto/howto_js_scroll_to_top.asp
document.addEventListener("turbolinks:load", () => {
  el = document.querySelector('#up_button')
  el.addEventListener('click', topFunction);


  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction(el)
  window.onscroll = function() {scrollFunction(el)};
})

function scrollFunction(el) {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    el.style.visibility = "visible";
    el.style.opacity = "1";
  } else {
    el.style.visibility = "hidden";
    el.style.opacity = "0";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  $('html,body').animate({ scrollTop: 0 }, 'slow');
  // document.body.scrollTop = 0; // For Safari
  // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
} 
