// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import { importDir } from '../js/utils/importDir';
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import $ from 'jquery'

window.$ = $
Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "channels"

importDir(require.context('../images', true), false)
importDir(require.context('../js', true, /(?<!archive.*).js$/));
importDir(require.context('../../components', true, /(?<!archive.*).js$/));
importDir(require.context('../../components_alchemy/alchemy_element', true, /(?<!archive.*).js$/));

