// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.swiper').forEach(el => {
    const nrOfSlides = el.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length

    // init Swiper:
    new Swiper(el, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Autoplay],
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      autoplay: {
        delay: 5000,
      },
      loop: true, // Can be changed to rewind
      // rewind: true
      speed: 1000,
      slidesPerView: "auto", // Remove (sometimes inacurate js style widths etc to rely on css
      loopedSlides: nrOfSlides // Remove if using rewind
    });
  })
})

document.addEventListener('turbolinks:before-cache', () => {
  document.querySelectorAll('.swiper').forEach(el => {
    el.swiper.slideToLoop(0, 0, false) // If using rewind, method name: slideTo
  })
})
