document.addEventListener("turbolinks:load", () => {
  el = document.querySelector("#newsletter_form .form-field input")
  if (el) {
    el.value = ""
  }

  privacy = document.querySelector("#reservation_accept_privacy, #newsletter_accept_privacy")
  if (privacy) {
    privacy.checked = false
  }
})
