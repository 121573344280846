// https://www.w3schools.com/howto/howto_js_scroll_to_top.asp
document.addEventListener("turbolinks:load", () => {
  el = document.querySelector('#reservation-button')
  
  if (el) {
    el.addEventListener('click', scrollToReservation);
  }
})

// When the user clicks on the button, scroll to the top of the document
function scrollToReservation() {
  $('html,body').animate({ scrollTop: $("#event-reservation").offset().top }, 'slow');
} 
