document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.hamburger').forEach(hamburger => {
    hamburger.addEventListener('click', () => {
      toggleHamburger(hamburger)
    })
  })
})

document.addEventListener('turbolinks:before-cache', () => {
  document.querySelectorAll('.hamburger').forEach(hamburger => {
    closeHamburger(hamburger);
  })
})

function hamburgerToggle(hamburger) {
  const toggleId = hamburger.getAttribute('aria-controls')
  return toggleId ? document.getElementById(toggleId) : false
}

function toggleHamburger(hamburger) {
  const toggle = hamburgerToggle(hamburger);
  if (!toggle) return

  if (toggle.classList.contains('is-open')) {
    closeHamburger(hamburger, toggle)
  } else {
    openHamburger(hamburger, toggle)
  }
}

function closeHamburger(hamburger, toggle = null) {
  hamburger.classList.remove('is-active')
  if (!toggle) toggle = hamburgerToggle(hamburger);
  if (toggle) toggle.classList.remove('is-open')
}

function openHamburger(hamburger, toggle = null) {
  hamburger.classList.add('is-active')
  if (!toggle) toggle = hamburgerToggle(hamburger);
  if (toggle) toggle.classList.add('is-open')
}
